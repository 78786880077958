.ellipsize {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

html,
.native-scroll {
  overflow: auto;
  position: relative;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #e9e9e9;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 24px;
    width: 4px;
  }
}
