@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

@import 'variables';
@import 'forms';
@import 'buttons';
@import 'helpers';
@import 'typography';
@import 'grid';
@import 'steps-common.scss';
@import 'theming';
@import 'material/material.scss';

html {
  overflow: hidden;
  height: 100%;
  background-color: #fff;
}

body {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  background-color: map-get($di-grey-map, 600);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: map-get($di-grey-map, 600);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  // enable only for selected controls
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.toast {
  max-width: 600px;

  &.error {
    background-color: map-get($custom-map, light-pink);
    color: map-get($custom-map, red-warning);
  }

  &.info {
    background-color: map-get($di-success-map, 600);
    color: #fff;
  }
}

.col .row {
  padding: 0px;
  margin-right: 0px;
  margin-left: 0px;
}

.row .col {
  padding: 0px;
  margin-right: 0px;
  margin-left: 0px;
}

.hide {
  opacity: 0;
}
