/* You can add global styles to this file, and also import other style files */
@import '~materialize-css/sass/components/_color-variables.scss';
@import '~materialize-css/sass/materialize.scss';
@import 'scss/style';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'scss/modento-material-theme';

@import 'scss/modento-radio';
@import 'scss/di-radio';
@import 'scss/modento-input';
@import 'scss/modento-select';
@import 'scss/theming';

:root {
  --di-book-custom-primary-color: #{map-get($di-primary-map, 500)};
  --di-book-custom-secondary-color: #{map-get($accent-map, A100)};
  --di-book-custom-background-color: #{map-get($di-grey-map, 50)};
  --di-book-shadow-primary-color: #{map-get($custom-map, default-shadow-color)};
  --di-book-shadow-secondary-color: #{map-get($custom-map, default-shadow-color)};
}

.app-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: 0 1 auto;
  align-self: center;
}

// fix for buttons with white background to make text visible
button.white {
  color: map-get($di-grey-map, 600) !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: var(--font-default) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: var(--font-default) !important;
}

div.spinner-layer.spinner-white-only {
  @extend .spinner-blue-only;

  border-color: #fff;
}

::ng-deep mat-datepicker-content button.mat-calendar-body-cell:focus {
  background-color: unset;
}

.mat-calendar button:focus {
  background-color: unset !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.mat-datepicker-content-container,
div#mat-autocomplete-0,
.mat-bottom-sheet-container-large,
.mat-dialog-container {
  background-color: var(--di-book-custom-secondary-color);
}

button {
  &:focus {
    outline: 2px solid var(--di-book-custom-primary-color);
    outline-offset: 1px;
  }
}

.circle-container {
  .mat-icon {
    circle {
      fill: var(
        --di-book-mat-icon-cicle-fill-color,
        var(--di-book-custom-primary-color)
      );
    }
  }
}
