@import 'theming';

.modento-select {
  transition: background-color 300ms cubic-bezier(0.55, 0, 0.55, 0.2);

  &.mat-form-field {
    .mat-form-field-flex {
      padding-top: 0 !important;
    }

    .mat-select-arrow {
      color: map-get($custom-map, primary-text-grey);
    }

    .mat-form-field-underline {
      background-color: map-get($custom-map, primary-text-grey);
    }

    .mat-select-arrow-wrapper {
      transform: translateY(0) !important;
    }

    .mat-form-field-wrapper:hover {
      .mat-form-field-underline {
        background-color: map-get($di-grey-map, 600);
      }

      .mat-form-field-ripple {
        display: none !important;
      }
    }
  }

  label {
    color: map-get($custom-map, primary-text-grey);
  }
}
