@import 'src/scss/theming';
mat-chip.mat-chip.mat-standard-chip {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  padding-left: 8px;

  &:not(.disabled) {
    background: map-get($di-primary-map, 50);
    color: map-get($di-primary-map, 700);
    &:hover {
      background: map-get($di-primary-map, 100);
      color: map-get($di-primary-map, 800);
    }

    &:focus {
      background: map-get($di-primary-map, 100);
      color: map-get($di-primary-map, 800);
    }

    &:after {
      display: none;
    }

    button.mat-chip-remove {
      opacity: 1;
      margin-left: 4px;

      &:hover {
        opacity: 1;
      }

      mat-icon {
        color: map-get($di-primary-map, 500);
        font-weight: 900;
      }
    }
  }
}
