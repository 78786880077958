@import 'variables';
@import 'theming';

.btn {
  font-weight: 500;
  font-family: 'Red Hat Text', sans-serif;
  text-transform: none;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  padding: 12px 24px;
  height: 44px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-left: 8px;
  }

  mat-icon {
    margin-right: 8px;
  }

  &.blue:not(.disabled) {
    box-shadow: 0 2px 2px 0 rgba(42, 116, 208, 25%),
      0 3px 1px -2px rgba(42, 116, 208, 25%),
      0 1px 5px 0 rgba(42, 116, 208, 25%);
  }
}

.btn-flat {
  color: map-get($di-primary-map, 500);

  &:hover {
    color: map-get($di-primary-map, 400);
    background-color: transparent;
  }
}

.button-continue {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 20px;

  button:not(.disabled)[type='submit'] {
    box-shadow: unset;
    background-color: var(--di-book-custom-primary-color);
  }

  button.btn.btn-flat {
    color: var(--di-book-custom-primary-color);

    mat-icon {
      color: var(--di-book-custom-primary-color);
    }
  }
}

.select-button-selected {
  background-color: var(--di-book-custom-secondary-color);
}

.select-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: $form-width;
  position: relative;
  padding: 16px 16px 16px 24px;

  .panel-radio {
    display: flex;
    color: map-get($di-primary-map, 500);

    [type='radio'] + span:before,
    [type='radio'] + span:after {
      height: 16px;
      width: 16px;
      color: map-get($di-primary-map, 500);
    }

    span:after {
      background-color: map-get($di-primary-map, 500) !important;
    }

    span:after,
    span:before {
      border: 1px solid map-get($di-primary-map, 500) !important;
    }
  }

  .check-box {
    flex: 0 1 auto;
    text-align: right;
    margin-top: 18px;
    color: map-get($di-primary-map, 500);

    [type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
      border-color: map-get($custom-map, secondary-text-grey);
    }
  }

  .add-new-text {
    color: map-get($di-primary-map, 500);
    font-size: 14px;
    margin-left: 30px;
    margin-top: -4px;
  }

  .panel-text {
    font-weight: 600;
    font-size: 16px;
    color: map-get($custom-map, primary-text-grey);
  }

  .panel-text-btn {
    font-weight: 500;
    font-size: 16px;
    display: block;
    float: left;
    margin-top: 16px;
    margin-left: 20px;
    cursor: pointer;
  }

  &.no-effects {
    pointer-events: none;
  }
}

.select-button [type='radio']:not(:checked) + span:before,
.select-button [type='radio']:not(:checked) + span:after {
  border-color: map-get($di-primary-map, 500);
}

.footer-mobile {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: $footer-height;
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  padding: 0 24px;
  padding-bottom: 18px;
  background-color: map-get($custom-map, light-grey-color);

  .button-continue-mobile {
    display: flex;
    flex: 1 1 auto;
    align-self: flex-end;
    text-align: center;
    justify-content: flex-end;
    gap: 8px;

    button {
      flex: 0 0 calc(50% - 4px);
      max-width: $form-width;
      font-weight: 500;
      color: #fff;
      font-size: 16px;
      border-radius: 4px;
      padding: 12px 24px;
      height: 44px;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      i,
      mat-icon {
        margin-right: 8px;
      }
    }

    .btn-back {
      color: map-get($di-primary-map, 500);
      background-color: map-get($custom-map, light-grey-color);
      box-shadow: none;
    }

    .btn-flat {
      color: map-get($di-primary-map, 500);

      &:hover {
        color: map-get($di-primary-map, 400);
        background-color: transparent;
      }
    }
  }
}

.btn[disabled] {
  color: #fff !important;
  background-color: map-get($di-grey-map, 300) !important;
}

button.btn.modento-btn--wave {
  //border-top-left-radius: 40px;
  border-radius: 40px 0 40px 0;
  padding: 18px 48px;
  font-size: 16px;
  font-weight: 500;
  height: auto;
}
