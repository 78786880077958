@import 'theming';

.modento-input {
  position: relative;

  input[type='text'],
  input[type='number'],
  input[type='email'],
  textarea {
    font-family: 'Red Hat Text', sans-serif;
    box-shadow: none !important;
    border-bottom: 1px solid map-get($custom-map, primary-text-grey);

    &::placeholder {
      font-size: 16px;
      color: map-get($custom-map, secondary-text-grey);
    }

    & + label {
      color: map-get($custom-map, primary-text-grey);
    }

    &:hover {
      border-bottom: 1px solid map-get($di-grey-map, 600);
    }

    &:focus:not([readonly]) {
      color: map-get($di-grey-map, 600);
      border-bottom: 1px solid map-get($di-primary-map, 500);

      & + label {
        color: map-get($di-primary-map, 500);
      }
    }

    &:focus.invalid {
      & + label {
        color: map-get($warn-map, 500);
      }
    }

    &.valid {
      color: map-get($di-grey-map, 600);
      border-bottom: 1px solid map-get($custom-map, primary-text-grey);

      & + label {
        color: map-get($di-grey-map, 600);
      }
    }

    &:disabled {
      color: map-get($custom-map, secondary-text-grey);
      border-bottom: 1px solid map-get($custom-map, secondary-text-grey);

      & + label {
        color: map-get($custom-map, secondary-text-grey);
      }
    }

    & ~ p {
      margin: 0;
      font-size: 12px;
      text-align: left;

      &.hint {
        color: map-get($custom-map, primary-text-grey);
      }

      &.error-message {
        color: map-get($warn-map, 500);
      }
    }

    &.invalid,
    &.has-error {
      color: #000;
      border-bottom: 1px solid map-get($warn-map, 500);

      & + label {
        color: map-get($warn-map, 500);
      }
    }

    & ~ mat-icon {
      position: absolute;
      top: 12px;
      right: 0;

      &.clickable {
        cursor: pointer;
      }
    }
  }

  textarea {
    border-inline: none;
    border-top: none;
    resize: none;
    outline: none;
    padding: 0;
    min-height: 25px;
    margin-top: 8px;

    &[cdkTextareaAutosize] {
      overflow: hidden;
    }
  }

  &.input-field {
    input {
      height: 2.6rem;
    }

    mat-datepicker-toggle {
      position: absolute;
      right: 0;

      button:focus {
        background-color: unset;
      }
    }
  }
}
