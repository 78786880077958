@import 'theming';

.modento-radio {
  display: flex;
  align-items: baseline;
  margin-top: 0;

  label {
    color: map-get($di-grey-map, 400);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.1px;
    margin: 0;
    min-height: 16px;
    padding-left: 12px;
    position: relative;
    vertical-align: middle;

    &:before {
      content: '';
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: -11px;
      border: 1px solid var(--di-book-custom-primary-color);
      border-radius: 50%;
      background-color: #fff;
      transition: border 0.15s ease-in-out;
    }

    &:after {
      display: inline-block;
      position: absolute;
      content: ' ';
      width: 8px;
      height: 8px;
      left: -7px;
      top: 4px;
      border-radius: 50%;
      background-color: map-get($di-grey-map, 400);
      transform: scale(0, 0);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    }
  }

  input[type='radio'] {
    margin: 0;
    position: relative;
    transform: scale(0);

    &:hover {
      & + label {
        &::before {
          border-color: var(--di-book-custom-primary-color);
        }

        &::after {
          background-color: var(--di-book-custom-primary-color);
        }
      }
    }

    &:disabled {
      & + label {
        &::before {
          border-color: map-get($di-grey-map, 300);
        }

        &::after {
          background-color: map-get($di-grey-map, 300);
        }
      }
    }

    &:disabled {
      cursor: not-allowed;

      & + label {
        color: map-get($di-grey-map, 300);
        cursor: not-allowed;
      }
    }

    &:checked {
      & + label {
        &::after {
          transform: scale(1, 1);
        }
      }

      &:not(:disabled) {
        & + label {
          &::before {
            border-color: var(--di-book-custom-primary-color);
          }

          &::after {
            background-color: var(--di-book-custom-primary-color);
          }
        }

        &:hover {
          & + label {
            &::before {
              border-color: var(--di-book-custom-primary-color);
            }

            &::after {
              background-color: var(--di-book-custom-primary-color);
            }
          }
        }
      }

      &:disabled {
        & + label {
          &::before {
            border-color: map-get($di-primary-map, 300);
          }

          &::after {
            background-color: map-get($di-primary-map, 300);
          }
        }
      }
    }
  }
}
