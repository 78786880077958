@use '@angular/material' as mat;

:root {
  --font-default: 'Red Hat Text', sans-serif;
}

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: 'Red Hat Text, sans-serif',
  $headline: mat.define-typography-level(14px, 14px, 400),
  $body-1: mat.define-typography-level(14px, 14px, 400),
);

@include mat.all-component-typographies($custom-typography);

// Override the typography in the core CSS.
@include mat.core($custom-typography);
