.modento-radio {
  input[type='radio'] {
    transform: initial;
    opacity: initial;
    pointer-events: initial;
    accent-color: var(--di-book-custom-primary-color);
  }

  label {
    &::before,
    &::after {
      content: unset;
      display: none;
    }
  }
}
