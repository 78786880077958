@import 'variables';
@import 'theming';

.form-box,
.form-card {
  margin-bottom: 8px;
  box-shadow: 0 1px 5px 0 rgba(178, 177, 184, 0.33);
  border-radius: 4px;

  .select-button {
    background-color: var(--di-book-custom-secondary-color);
  }
}

.form-content {
  width: $form-width;
  background-color: var(--di-book-custom-secondary-color);
  border-top: 1px solid var(--di-book-custom-background-color);

  form {
    padding: 32px 24px 16px 24px;

    > .row {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .form-wrapper {
    width: 100%;
  }

  .input-grouped {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .input-field {
    flex-grow: 1;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .single-field {
    width: calc(50% - 12px);
  }

  .form-question {
    text-align: left;
    font-size: 16px;
    color: map-get($di-grey-map, 600);
  }

  .radio-group {
    display: flex;
    flex-direction: row;

    > .modento-radio:not(:last-child) {
      margin-right: 20px;
    }
  }

  .name-surname-birthday {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-top: 20px;

    .name {
      width: calc(33% - 20px);
      display: block;
    }

    .surname {
      width: calc(33% - 20px);
      display: inline-block;
      margin-left: 10px;
    }

    .birthday {
      width: calc(33% - 10px);
      display: inline-block;
      margin-left: 10px;
      p.fixed-label {
        margin-top: -14px;
        margin-bottom: -4px;
      }
    }
  }

  .role-birthday {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-top: 20px;

    .role {
      width: calc(50% - 30px);
    }

    .birthday {
      width: calc(50% - 30px);
      display: inline-block;
      margin-left: 30px;
    }
  }

  .form-new {
    width: $form-width;
    background-color: #fff;
  }

  input {
    margin-bottom: 0px;
  }

  .select-wrapper {
    font-family: var(--font-default);
  }

  .select-wrapper input.select-dropdown {
    border-bottom: 1px solid #57ab00;
    box-shadow: 0 1px 0 0 #57ab00;
  }
}

.container {
  margin-top: 110px;
  .dont-go-checkbox {
    [type='checkbox'] + span:not(.lever) {
      font-size: 14px !important;
      margin-top: -2px;
    }

    display: block;
    margin-left: 0px;
    width: 250px;
    float: left;
    margin-top: 20px;
    text-align: left;
  }

  .margin-checkbox {
    margin-left: 0px;
  }
}

.container-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 24px;
  padding-bottom: $footer-height;

  > .row {
    width: 100%;
  }

  .step__title-wrapper {
    width: 100%;
    padding: 0 24px;
  }

  .text-align-left {
    width: 100vw;
    margin-left: 15px;
    font-weight: 500;
  }

  .form-field {
    margin-top: 10px;
  }

  .scroll {
    overflow: scroll;
    height: 900px;
    margin-bottom: 25px;
  }

  .dont-go-checkbox-mobile {
    width: 340px;
    margin-top: 20px;
    margin-left: -40px;
    color: map-get($di-grey-map, 600);
  }

  .check-label {
    width: 50%;
  }

  .margin-checkbox {
    margin-left: 0px;
  }
}

.step-title {
  margin-bottom: 24px;

  &-text {
    text-align: left;
    margin: 0;
    font-size: 26px;
    font-weight: 500;
    color: map-get($di-grey-map, 600);
  }

  .container-mobile & {
    margin-bottom: 16px;

    &-text {
      font-size: 18px;
    }
  }
}

.row-table {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.check-label {
  width: 50%;
}

.button-row {
  margin-left: 40px;
}

[type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
  border-color: map-get($custom-map, secondary-text-grey);
}

[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border: map-get($di-primary-map, 500);
  background-color: map-get($di-primary-map, 500);
}

p.fixed-label {
  margin: 0;
  font-size: 0.8rem;
  color: map-get($custom-map, secondary-text-grey);
  text-align: left;

  &.focused {
    color: #57ab00;
  }
  &.valid {
    color: #57ab00;
  }
}

.helper-text {
  p + & {
    margin-top: -10px !important;
    margin-bottom: 15px;
  }
}

.helper-text.invalid {
  color: map-get($custom-map, red-warning);
}

tr {
  border-bottom: none;
}

.add-new {
  padding: 16px 0;

  &-text {
    color: map-get($di-primary-map, 500);
  }
}
