@import 'src/scss/theming';

mat-checkbox.mat-checkbox {
  max-width: 100%;
  .mat-checkbox-ripple {
    // neutralize materialize
    height: 40px;
    &::before {
      display: none;
    }
  }
  .mat-checkbox-frame {
    border-width: 1px;
  }
  .mat-checkbox-layout {
    max-width: 100%;

    .mat-checkbox-label {
      color: map-get($di-grey-map, 600);
      font-size: 16px;
    }
  }

  &.mat-primary {
    .mat-checkbox-frame {
      border-color: map-get($di-primary-map, 500);
    }

    &.mat-checkbox-checked {
      .mat-checkbox-background {
        background-color: rgba(256, 256, 256, 0);

        svg {
          .mat-checkbox-checkmark-path {
            stroke: map-get($di-primary-map, 500) !important;
          }
        }
      }
    }

    &.mat-checkbox-disabled {
      .mat-checkbox-label {
        color: map-get($di-grey-map, 200) !important;
      }
      .mat-checkbox-frame {
        border-color: map-get($di-grey-map, 200) !important;
      }

      &.mat-checkbox-checked {
        .mat-checkbox-background {
          background-color: rgba(256, 256, 256, 0);

          svg {
            .mat-checkbox-checkmark-path {
              stroke: map-get($custom-map, disabled-font) !important;
            }
          }
        }
      }
    }
  }
}
