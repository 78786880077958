@import 'src/scss/theming';
@import 'src/scss/helpers';

.cdk-overlay-pane {
  background: transparent;
  box-shadow: 0 1px 5px 0 rgba(178, 177, 184, 0.33);
  border-radius: 4px;
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

[class$='cdk-overlay-pane'] {
  &[style*='pointer-events: none;'] {
    visibility: hidden;
  }
}

.mat-autocomplete-panel {
  box-shadow: none !important;

  .mat-option {
    height: 32px;
    line-height: 32px;

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    &.option--button {
      font-weight: 500;
    }
  }

  .mat-option-text {
    color: map-get($di-grey-map, 600);
    font-size: 16px;
    max-width: 100%;
  }

  &.autocomplete--checkboxes {
    .mat-option-text {
      overflow: visible;
    }

    .mat-checkbox-label {
      @extend .ellipsize;
    }
  }

  &.is-in-iframe {
    max-height: 180px !important;
  }
}

.mat-icon.autocomplete__arrow {
  height: 18px;
}
