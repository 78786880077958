@use '@angular/material' as mat;
@import 'theming';

$primary: mat.define-palette($di-primary-map);
$accent: mat.define-palette($accent-map);
$warn: mat.define-palette($warn-map);
$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-component-themes($theme);

mat-dialog-container {
  &.mat-dialog-container {
    padding: 16px;
    border-radius: 4px;
  }
}

.responsive-dialog {
  width: 95vw;
  max-width: 650px !important;
}
