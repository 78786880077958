@import 'src/scss/theming';

mat-form-field.mat-form-field {
  .mat-form-field-label {
    // default state
    color: map-get($di-grey-map, 600);
  }

  .mat-form-field-underline {
    height: 1px !important;
    background-color: map-get($custom-map, primary-text-grey);
  }
  .mat-form-field-ripple {
    height: 1px !important;
    background-color: map-get($custom-map, primary-text-grey);
  }
  &.mat-form-field-appearance-standard {
    .mat-form-field-flex {
      padding-top: 0;
    }

    .mat-form-field-infix {
      padding-bottom: 6px;
    }

    input {
      border-bottom: none !important;
      box-shadow: none !important;
      height: auto;
      line-height: 30px;
      margin-bottom: 0 !important;
    }

    .mat-form-field-label-wrapper {
      line-height: 30px;
    }

    button {
      background-color: transparent;
    }
  }

  &.form-field--brown-warning {
    .mat-hint {
      color: map-get($custom-map, brown-warning);
    }
    .mat-form-field-label {
      // default state
      color: map-get($custom-map, brown-warning);
    }
    .mat-form-field-underline {
      background-color: map-get($custom-map, brown-light-warning) !important;
      .mat-form-field-ripple {
        background-color: map-get($custom-map, brown-light-warning) !important;
      }
    }
  }
}

// adjust floating label to materialize other inputs to float at the same height
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.45em) scale(0.75) !important;
}
